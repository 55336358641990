<template>
  <div restrict-location>
    <h2>{{ $t('_.title') }}</h2>
    <FontIcon name="ban"/>
    <p class="msg" v-html="$t('_.description')" />
    <div class="area-box">
      <h4>{{ $t('_.boxTitle') }}</h4>
      <ul>
        <li v-for="(item,id) in areaList" :key="id"><label>{{ $t(`_.${ item }`) }}</label></li>
      </ul>
    </div>
    <ProgressButton button-name="close" @click="close"/>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'RestrictLocation',
  components: { ProgressButton, FontIcon },
  lexicon: 'signUp.restrictLocation',
  data() {
    return {
      areaList: ['Telangana', 'AndhraPradesh', 'Sikkim', 'Nagaland', 'ArunachalPradesh', 'Odisha', 'Assam']
    };
  },
  methods: {
    close() {
      close(this);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[restrict-location] { .tc(); .mt(80);
  h2 { .c(@c-red); .medium(); .fs(36); .mb(56);
    &+[font-icon] { .fs(50); .block();  .mh-c(); .c(@c-red); }
  }
  .msg { .fs(16,22); .c(#9e9e9e); .m(24,0,30); }
  .area-box { .mb(80);  .-a(#9e9e9e); .pb(16);
    h4 { .fs(16); .medium(); .pv(16); }
    ul { .ib-list(); .tl();
      li { .w(50%); .-box(); .ph(4%);
        label { .rel(); .pl(16); .ib(); .fs(14, 16);
          &:before { .cnt(); .abs(); .wh(4); .bgc(white); .br(50%); .lt(0,50%); .t-y(-50%);}
        }
      }
    }
  }
  @media(@tp-up) {
    .area-box {
      ul {
        li { .ph(8%);}
      }
    }
  }
}
</style>